<script>
  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';

  import config from './config';
  import { storeLink } from './utils';

  const { backendUrl } = process.env === 'dev' ? config.dev : config.prod;
  const dispatch = createEventDispatcher();
  let url = '';
  let placeholderUrl = 'https://www.youtube.com/watch?v=Lrj2Hq7xqQ8';
  let notificationVisible = false;
  let notificationMessage = '';

  const showNotification = (message, timeout = 2500) => {
    notificationMessage = message;
    notificationVisible = true;
    setTimeout(() => {
      notificationVisible = false;
    }, timeout);
  };

  async function handleShorten(e) {
    e.preventDefault();

    if (url.indexOf('http') !== 0) {
      url = `https://${url}`;
    }

    try {
      const urlValidator = new URL(url);
      if (!url.includes('.')) {
        url = '';
        throw new Error();
      }
    } catch {
      showNotification('Invalid URL!');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url }),
      });
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.Error);
      }

      if (data.Result) {
        storeLink({ url, link: `${backendUrl}/${data.Result}` });
        dispatch('update');
        url = '';
      }
    } catch (e) {
      showNotification(e.message);
    }
  }
</script>

<p>Paste a URL to get a shortened link</p>
<form on:submit={handleShorten}>
  <input bind:value={url} placeholder={placeholderUrl} />
  <button on:click={handleShorten}>Shorten!</button>
</form>
{#if notificationVisible}
  <div transition:fade={{ duration: 125 }} class="notification">{notificationMessage}</div>
{/if}

<style lang="scss">
  @import './styles/vars.scss';

  form {
    display: flex;
  }

  input {
    margin-right: 1rem;
    flex-grow: 1;
  }

  input,
  button {
    @include button-style;
    font-size: $font-size-sml;
    padding: 0.4em;
    color: #333;

    outline: none;
  }

  button:active {
    @include button-style-clicked;
  }

  .notification {
    position: absolute;
    margin-top: $sep-tiny;
    padding: $sep-tiny;
    border-radius: 50px;
    color: $yellow;
    background-color: $bg-dark;
    font-size: $font-size-sml;
    z-index: 1000;
  }
</style>
