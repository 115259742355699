export const storeLink = (link) => {
  let storedLinks = JSON.parse(localStorage.getItem('storedLinks')) || [];

  storedLinks = [link, ...storedLinks];

  localStorage.setItem('storedLinks', JSON.stringify(storedLinks));
};

export const loadLinks = (number = 5) => {
  return (JSON.parse(localStorage.getItem('storedLinks')) || []).slice(0, number);
};

export function copyToClipboard(str) {
  const dummyClipboard = document.createElement('textarea');
  document.body.appendChild(dummyClipboard);
  dummyClipboard.value = str;
  dummyClipboard.select();
  dummyClipboard.select();
  dummyClipboard.setSelectionRange(0, 99999);
  document.execCommand('copy');
  document.body.removeChild(dummyClipboard);
}
