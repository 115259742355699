<script>
  import Form from './Form.svelte';
  import Link from './Link.svelte';

  import { loadLinks } from './utils';

  let links = loadLinks();

  function handleUpdate() {
    links = loadLinks();
  }
</script>

<main>
  <header>
    <h1>MeneLink</h1>
  </header>
  <section>
    <Form on:update={handleUpdate} />
  </section>
  <section>
    <ul>
      {#each links as link}
        <Link {...link} />
      {/each}
    </ul>
  </section>
  <footer class="footer">
    <span>Meneillos</span>
  </footer>
</main>

<style lang="scss">
  @import './styles/vars.scss';

  main {
    padding-bottom: $footer-height + $sep;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
  }

  section {
    max-width: $max-width;
    margin: 0 auto $sep auto;
    text-align: center;
  }

  header {
    background: grad-barberpole(80);
    box-shadow: $shadow-downwards;
    margin-bottom: $sep;
  }

  h1 {
    text-align: center;
    margin: 0;
    font-family: 'Sora';
    font-size: 3em;
    font-weight: 100;
    text-shadow: 0px 0px 3px #fff, 0px 0px 3px #fff, 0px 0px 3px #fff, 0px 0px 3px #fff,
      0px 0px 3px #fff, 0px 0px 3px #fff, 0px 0px 3px #fff;
  }

  ul {
    padding: 0 $sep;
  }

  footer {
    @include footer;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: $font-size-sml;
    color: $text-light;
    background-color: $bg-mid;
    box-shadow: $shadow-upwards;
  }
</style>
