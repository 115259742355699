<script>
  import { fade } from 'svelte/transition';
  import { spring } from 'svelte/motion';

  import { copyToClipboard } from './utils';

  export let url;
  export let link;
  let linkLabel;

  let tooltipVisible = false;
  let coords = spring({ x: 0, y: 0 }, { stiffness: 0.1, damping: 0.25 });

  $: linkLabel = link.replace(/^https?\:\/\//i, '');

  function handleMouseOver(e) {
    tooltipVisible = true;
    coords.set({ x: e.pageX + 20, y: e.pageY + 20 }, { hard: true });
  }

  function handleMouseMove(e) {
    coords.set({ x: e.pageX + 20, y: e.pageY + 20 });
  }

  function handleMouseOut() {
    tooltipVisible = false;
  }

  function handleContainerClick() {
    tooltipVisible = false;
    copyToClipboard(link);
  }

  function handleLinkClick(e) {
    e.stopPropagation();
  }
</script>

<li
  on:mouseover={handleMouseOver}
  on:mousemove={handleMouseMove}
  on:mouseout={handleMouseOut}
  on:click={handleContainerClick}
>
  <div class="striped" />
  <a
    on:mouseenter={handleMouseOut}
    on:click={handleLinkClick}
    class="link"
    href={link}
    target="_blank"
  >
    {linkLabel}
  </a>
  <a
    on:mouseenter={handleMouseOut}
    on:mouseover={handleMouseOut}
    class="link sml ellipsize"
    href={url}
    target="_blank"
  >
    {url}
  </a>
</li>
{#if tooltipVisible}
  <div
    class="tooltip"
    transition:fade={{ duration: 125 }}
    style={`top: ${$coords.y}px; left: ${$coords.x}px`}
  >
    Click to copy!
  </div>
{/if}

<style lang="scss">
  @import './styles/vars.scss';

  li {
    @include button-style;
    box-sizing: border-box;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $bg-lightest;
    margin-bottom: $sep-sml;
    height: 74px;

    &:active {
      @include button-style-clicked;
    }

    &:hover .striped {
      background: grad-barberpole(14, lighten($yellow, 20));
      box-shadow: square-shadow(lighten($yellow, 20), true);

      background-size: 79px 100%;
      animation: barberpole 20s ease infinite;
    }

    &:active .striped {
      border-bottom: 0;
      height: 72px;
    }
  }

  .link {
    align-self: center;
    padding: 0 $sep-sml;
    border-radius: 50px;

    &:hover {
      color: $yellow;
      background-color: $bg-dark;
    }
  }

  .sml {
    font-size: $font-size-tiny;
    font-weight: 400;
  }

  .ellipsize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 75%;
  }

  .striped {
    position: absolute;
    height: 70px;
    width: 50px;
    background: grad-barberpole(14);
    background-size: 79px 100%;
  }

  .tooltip {
    position: absolute;
    padding: $sep-tiny;
    border-radius: 50px;
    color: $yellow;
    background-color: $bg-dark;
    font-size: $font-size-sml;
    z-index: 1000;
  }

  @keyframes barberpole {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 790px 0;
    }
  }
</style>
