const config = {
  dev: {
    backendUrl: 'http://localhost:8080',
  },
  prod: {
    backendUrl: window.location.origin,
  },
};

export default config;
